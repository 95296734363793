<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <!-- <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message> -->
                <Error :errors="errors"/>

                <Fieldset legend="Tambah Baru" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="kode_mr">Kode MR</label>
                                <InputText id="kode_mr" v-model="forms.kode_mr" required="true" autofocus :class="{ 'p-invalid': errorAdd.kode_mr }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.kode_mr" >{{ errorAdd.kode_mr[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="name_mr">Nama MR</label>
                                <InputText id="name_mr" v-model="forms.name_mr" required="true" autofocus :class="{ 'p-invalid': errorAdd.name_mr }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.name_mr" >{{ errorAdd.name_mr[0] }}</small>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="jabatan">Jabatan</label>
                                <Dropdown id="jabatan" v-model="forms.jabatan" :loading="loadingDropdownJabatanAdd" :options="dataDropdownJabatanAdd" :class="{ 'p-invalid': errorAdd.jabatan }" optionLabel="level_name" optionValue="level_name" placeholder="Pilih Jabatan" :filter="true" :showClear="true" @filter="searchDropdownJabatan($event ,'add')"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.jabatan" >{{ errorAdd.jabatan[0] }}</small>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownMedrep">SPV</label>
                                <Dropdown v-model="forms.spv" :loading="loadingDropdownSpvAdd" :options="dataDropdownSpvAdd" optionLabel="label" optionValue="id_mr" placeholder="Pilih SPV" :filter="true" :showClear="true" @filter="searchDropdownSpv($event, 'add')"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.spv" >{{ errorAdd.spv[0] }}</small>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loadingAddNew" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
                </Fieldset>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label>Jabatan</label>
                                <Dropdown v-model="filters.jabatan" :loading="loadingDropdownJabatanFilter" :options="dataDropdownJabatanFilter" optionLabel="level_name" optionValue="level_name" placeholder="Pilih Jabatan" :filter="true" :showClear="true" @filter="searchDropdownJabatan($event, 'filter')"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="cust_id" @sort="onSort($event)">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="kode_mr" header="Kode MR">
                        <template #body="slotProps">
                            {{slotProps.data.kode_mr}}
                        </template>
                    </Column>
                    <Column field="name_mr" header="Nama MR">
                        <template #body="slotProps">
                            {{slotProps.data.name_mr}}
                        </template>
                    </Column>
                    <Column field="jabatan" header="Jabatan">
                        <template #body="slotProps">
                              {{slotProps.data.jabatan}}
                        </template>
                    </Column>
                    <Column field="spv_medrep" header="SPV">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.spv_medrep !== null"> 
                                {{slotProps.data.spv_medrep.kode_mr}}-{{slotProps.data.spv_medrep.name_mr}}
                            </div>
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)"  v-if="permission.includes('update')"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

				<Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
					<div class="field">
						<label for="kode_mr">Kode MR</label>
                        <InputText id="kode_mr" v-model="item.kode_mr" required="true" autofocus :class="{ 'p-invalid': errorEdit.kode_mr }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.kode_mr" >{{ errorEdit.kode_mr[0] }}</small >
					</div>
					<div class="field">
						<label for="name_mr">Nama MR</label>
                        <InputText id="name_mr" v-model="item.name_mr" required="true" autofocus :class="{ 'p-invalid': errorEdit.name_mr }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.name_mr" >{{ errorEdit.name_mr[0] }}</small >
					</div>
					<div class="field">
                        <label for="jabatan">Jabatan</label>
                        <Dropdown id="jabatan" ref="djabatan" v-model="item.jabatan" :loading="loadingDropdownJabatanEdit" :options="dataDropdownJabatanEdit" :class="{ 'p-invalid': errorEdit.jabatan }" optionLabel="level_name" optionValue="level_name" placeholder="Pilih Jabatan" :filter="true" :showClear="true" @filter="searchDropdownJabatan($event,'edit')"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.jabatan" >{{ errorEdit.jabatan[0] }}</small>
					</div>
                    <div class="field">
                        <label for="dropdownEditJabatan">SPV</label>
                        <Dropdown ref="dspv" v-model="item.spv" :loading="loadingDropdownSpvEdit" :options="dataDropdownSpvEdit" optionLabel="label" optionValue="id_mr" placeholder="Pilih SPV" :filter="true" :showClear="true" @filter="searchDropdownSpv($event, 'edit')"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.spv" >{{ errorEdit.spv[0] }}</small>
                    </div>
					<template #footer>
						<Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loadingEdit"/>
					</template>
				</Dialog>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Error from '../../components/Error.vue';

export default {
	components: {
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingAddNew: false,
            loadingEdit: false,
            loadingDropdownJabatanAdd: false,
            loadingDropdownJabatanEdit: false,
            loadingDropdownJabatanFilter: false,
            loadingDropdownSpvAdd: false,
            loadingDropdownSpvEdit: false,
            loadingExcel: false,
            loadingCsv: false,

            // dataDropdown
            dataDropdownJabatanAdd: null,
            dataDropdownJabatanEdit: null,
            dataDropdownJabatanFilter: null,
            dataDropdownSpvAdd: null,
            dataDropdownSpvEdit: null,

            // addNew
            forms: {
                kode_mr: null,
                name_mr: null,
                jabatan: null,
                spv: null,
            },

            // edit
            item: {},
            editItemDialog: false,
            
            // filter
            filters: {
                jabatan: null,
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownJabatan('');
        this.searchDropdownSpv('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownJabatan(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.djabatan.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownJabatanFilter = true;
                }else if(purpose == "add"){
                    this.loadingDropdownJabatanAdd = true;
                }else if(purpose == "edit"){
                    this.loadingDropdownJabatanEdit = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/level-user-hp',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownJabatanFilter = res.data.data;
                        this.loadingDropdownJabatanFilter = false;
                    }else if(purpose == "add"){
                        this.dataDropdownJabatanAdd = res.data.data;
                        this.loadingDropdownJabatanAdd = false;
                    }else if(purpose == "edit"){
                        this.dataDropdownJabatanEdit = res.data.data;
                        this.loadingDropdownJabatanEdit = false;
                    }else if(purpose == null){
                        this.dataDropdownJabatanFilter = res.data.data;
                        this.dataDropdownJabatanAdd = res.data.data;
                        this.dataDropdownJabatanEdit = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownSpv(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dspv.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownSpvAdd = true;
                }else if(purpose == "edit"){
                    this.loadingDropdownSpvEdit = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-medrep',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownSpvAdd = res.data.data;
                        this.loadingDropdownSpvAdd = false;
                    }else if(purpose == "edit"){
                        this.dataDropdownSpvEdit = res.data.data;
                        this.loadingDropdownSpvEdit = false;
                    }else if(purpose == null){
                        this.dataDropdownSpvAdd = res.data.data;
                        this.dataDropdownSpvEdit = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loadingAddNew = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-medrep/create',
                data: {
                    "kode_mr": this.forms.kode_mr,
                    "name_mr": this.forms.name_mr,
                    "jabatan": this.forms.jabatan,
                    "spv": this.forms.spv,
                },
            })
            .then((res) => {
                this.loadingAddNew = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingAddNew = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.kode_mr = null;
            this.forms.name_mr = null;
            this.forms.jabatan = null;
            this.forms.spv = null;
        },
        // EDIT
		editItem(item) {
			this.item = item;
            
            // dropdown 
            this.searchDropdownJabatan('', 'edit', item.jabatan);
            this.searchDropdownSpv('', 'edit', item.spv != null ? item.spv_medrep.kode_mr + '-'+ item.spv_medrep.name_mr : null);   
            

			this.editItemDialog = true;
            this.$store.commit('setErrorEdit', {});

		},
		hideDialog() {
			this.editItemDialog = false;
			this.submitted = false;
		},
		updateItem() {
            this.loadingEdit = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-medrep/update',
                data: {
                    "id_mr" : this.item.id_mr,
                    "kode_mr" : this.item.kode_mr,
                    "name_mr" : this.item.name_mr,
                    "jabatan" : this.item.jabatan,
                    "spv" : this.item.spv,
                },
            })
            .then((res) => {
                this.loadingEdit = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.editItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingEdit = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-medrep',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "jabatan" : this.filters.jabatan,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/master-medrep/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "jabatan" : this.filters.jabatan,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Master Medrep Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>